.app_wrapper {
  min-height: 100vh;
  height: auto;

  --header-height: 64px;
  --sidebar-width: 400px;

  background-color: var(--background-color);
  color: var(--text-color);

  padding-top: var(--header-height);
}

header {
  z-index: 9;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: var(--header-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 32px;
  background-color: var(--background-color);
  color: var(--text-color);
  box-shadow: inset 0 -1px 0 0 var(--text-color);
}
header h1 {
  font-size: 40px;
}

nav {
  position: absolute;
  top: 64px;
  left: 0;
  width: var(--sidebar-width);
  padding: 32px;
  padding-block-end: 25vh;

  box-shadow: inset -1px 0 0 0 var(--text-color);
}

main {
  position: absolute;
  top: 64px;
  right: 0;
  left: var(--sidebar-width);
  padding: 32px;
  padding-block-end: 25vh;
  box-shadow: -1px 0 0 0 var(--text-color);
}

main p {
  width: 500px;
  max-width: 100%;
}

/* hide sidebar on small screens */
@media (max-width: 900px) {
  nav {
    display: none;
    z-index: 9;
    position: fixed;
    top: var(--header-height);
    left: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    padding-block-end: 25vh;
    box-shadow: none;
    background-color: var(--background-color);
    overflow: auto;
    -webkit-overflow-scrolling: touch;
  }
  .app_wrapper.show_filters nav {
    display: block;
  }
  .app_wrapper.show_filters {
    overflow: hidden;
  }
  main {
    left: 0;
    box-shadow: none;
  }
}

.tag_row {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin: 20px 0;
}
.tag_row.small {
  gap: 10px;
  margin: 10px 0;
}
.tag_row button {
  filter: saturate(2);
}
