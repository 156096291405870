body {
  margin: 0;
  /*font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


:root {
  --text-color: #000b13;
  --background-color: #fff;
  --primary-color: #007ed9;

  --content-box-width: 620px;

  --font-family-headline: "Ubuntu Mono", Ubuntu, sans-serif;
  --font-family-text: Ubuntu, sans-serif;
  --font-family-code: Ubuntu Mono, source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@media (prefers-color-scheme: dark) {
  :root {
    --text-color: #fff;
    --background-color: #000b13;
    --primary-color: #007ed9;
  }
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  outline: none;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth !important;
}

@media (max-width: 900px) {
  .hide_on_small_screens {
    display: none;
  }
}
@media (min-width: 900px) {
  .hide_on_large_screens {
    display: none;
  }
}

body {
  background: var(--background-color);
  color: var(--text-color);
  font-family: var(--font-family-text);
  transition: color .2s ease, background .2s ease;
}

h1 {
  font-family: var(--font-family-headline);
  font-weight: bold;
  font-size: 80px;
  letter-spacing: -1.5px;
}

h2 {
  font-family: var(--font-family-text);
  font-weight: bold;
  /* font-size: 40px; */
  font-size: 1.5rem;
  /* margin-bottom: 40px; */
}

h3 {
  font-family: var(--font-family-text);
  font-weight: bold;
  font-size: 20px;
  letter-spacing: 0px;
}
h3+p {
  margin-top: 10px;
}

body,
p,
button,
input,
textarea {
  font-family: var(--font-family-text);
  font-weight: normal;
  font-size: 20px;
  letter-spacing: 0.5px;
}
p {
  margin-bottom: 1rem;
}

.body2 {
  font-family: var(--font-family-text);
  font-weight: normal;
  font-size: 16px;
  letter-spacing: 0.25px;
}

button,
input[type="search"] {
  /* text-transform: uppercase; */
  border: 0;
  font-weight: bold;
  text-align: start;

	padding: 10px 20px;
	color: var(--background-color);
	fill: var(--background-color);
	background: var(--text-color);
	font-weight: bold;
	transition: box-shadow 0.2s ease;

  border-radius: 32px;
}
input[type="search"] {
  width: 100%;
}
button {
  cursor: pointer;
}
button.small {
  padding: 5px 10px;
  font-size: 14px;
  border-radius: 16px;
}
button[data-selected="true"] {
  box-shadow: 0 0 0 5px var(--text-color);
}
button.small[data-selected="true"] {
  box-shadow: 0 0 0 2px var(--text-color);
}
button:hover {
	box-shadow: 0 0 0 10px var(--text-color);
	color: var(--background-color);
	fill: var(--background-color);
	background: var(--text-color);
	text-decoration: none;
}
button.small:hover {
  box-shadow: 0 0 0 5px var(--text-color);
}
button:active,
button:focus,
button.active {
	color: var(--background-color);
	fill: var(--background-color);
	background: var(--text-color);
	text-decoration: none;
}
button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

code {
  font-family: var(--font-family-code);
}

a,
a:hover,
a:active,
a:focus {
  color: var(--text-color);
  text-decoration: underline;
  text-decoration-color: var(--text-color);
}
a:hover {
  text-decoration: underline;
  text-decoration-style: double;
  text-decoration-color: currentColor;
}
h3 > a,
h3 > a:hover,
h3 > a:active,
h3 > a:focus {
  text-decoration-style: unset;
}
h3 > a,
h3 > a:active,
h3 > a:focus {
  text-decoration: none;
}

hr {
  border: 0;
  height: 1px;
  background-color: var(--text-color);
  margin: 0;
}

